<template>
  <div>
    <el-dialog
      :title="LOCALE == 'en' ? 'Notice' : '提示'"
      :visible.sync="hintVisible"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="tc">
        <div>Please set up your available meeting time at first,</div>
        <div>then attend 1v1 meeting</div>

        <div class="talkSetBtn">
          <el-button
            type="primary"
            size="small"
            @click="$router.push('/console/conference/negotiationSetting')"
            >{{ $t("timeManagement") }}</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      hintVisible: false,
    };
  },
  methods: {
    hintVisibleShow(val) {
      console.log(val);
      this.hintVisible = val;
    },
  },
};
</script>
<style lang="less" scoped>
.talkSetBtn {
  margin-top: 30px;
}
</style>